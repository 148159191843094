var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!_vm.isLoadingData)?_c('div',{staticClass:"flex items-center flex-col w-full justify-center font-inter"},[(!_vm.hideExpandedView)?_c('div',{staticClass:"rounded-lg list-group w-full"},[(!_vm.hideMainAddSection)?_c('div',{staticClass:"mb-5 mx-10 relative rounded-lg bg-tasqNeutral1000 border-primary500",class:_vm.isAddItemSectionSelected ? 'border' : '  hover:border focus:border'},[_c('div',{staticClass:"flex flex-row justify-start items-center px-4",class:_vm.isAddItemSectionSelected ? 'py-2' : '',on:{"click":function($event){_vm.addItemFocus = true}}},[_c('span',{staticClass:"inline-block",class:_vm.listItem.checked
              ? 'tasq-checkbox--checked'
              : 'tasq-checkbox checkbox-style',attrs:{"type":"checkbox"},on:{"click":function($event){_vm.listItem.checked = !_vm.listItem.checked}}}),_c('GptAutoSuggestion',{staticStyle:{"width":"calc(100% - 40px)"},attrs:{"suggestions":_vm.userNames,"width":410,"skidding":4,"border-class":"border-none","trigger-character":"@","background":"bg-tasqNeutral1000 ","placeholder":"Type here to add","height":8,"auto-rezise":true,"match-whole-query":false},on:{"add-item":_vm.addItemToList},model:{value:(_vm.listItem.text),callback:function ($$v) {_vm.$set(_vm.listItem, "text", $$v)},expression:"listItem.text"}})],1),(_vm.isAddItemSectionSelected)?_c('div',{staticClass:"flex p-4 flex-row justify-start border-t border-opacity-10"},[_c('t-datepicker',{staticClass:"customized-datepicker bg-tasqNeutral1900 customized-datepicker--align-right rounded-2xl w-1/2 text-white",attrs:{"user-format":'F d, Y',"placeholder":"Select date its due"},model:{value:(_vm.listItem.dueDate),callback:function ($$v) {_vm.$set(_vm.listItem, "dueDate", $$v)},expression:"listItem.dueDate"}})],1):_vm._e(),(_vm.isAddItemSectionSelected)?_c('div',{staticClass:"flex justify-between border-t border-opacity-10 p-4"},[_c('tasq-button',{staticClass:"font-inter h-8 w-16 float-right",attrs:{"type":"tertiaryGhost","size":"small"},on:{"click":_vm.resetForm}},[_vm._v(" Cancel ")]),_c('tasq-button',{staticClass:"font-inter h-8 float-right",attrs:{"type":"primary","disabled":!_vm.listItem.text ||
            (_vm.listItem.dateType === 'Date' && !_vm.listItem.dueDate),"size":"small"},on:{"click":_vm.addItemToList}},[_vm._v(" Add to To-Do List ")])],1):_vm._e()]):_vm._e(),_c('draggable',{staticClass:"mx-3",attrs:{"id":`steps-list-input`},on:{"end":_vm.updateList},model:{value:(_vm.pendingItems),callback:function ($$v) {_vm.pendingItems=$$v},expression:"pendingItems"}},_vm._l((_vm.pendingItems),function(item,key){return _c('div',{key:item.id,staticClass:"mb-3 flex justify-between items-center",on:{"mouseover":function($event){return _vm.updateHoverItem(item)},"mouseleave":function($event){_vm.hoverItemId = ''}}},[[_c('mdicon',{staticClass:"cursor-pointer flex-none text-tasqNeutral300",attrs:{"name":"drag-horizontal-variant","size":"20"}}),_c('div',{staticClass:"flex w-full mx-2 flex-row justify-start items-start px-4 rounded-lg hover:border border-primary500 shadow item-bg"},[_c('span',{staticClass:"inline-block mt-3",class:item.checked
                ? 'tasq-checkbox--checked'
                : 'tasq-checkbox checkbox-style',attrs:{"type":"checkbox"},on:{"click":function($event){return _vm.updateItemState(item)}}}),_c('div',{staticClass:"w-full"},[_c('GptAutoSuggestion',{ref:item.id,refInFor:true,staticClass:"w-full",attrs:{"suggestions":_vm.userNames,"trigger-character":"@","width":415,"skidding":-8,"background":"bg-tasqNeutral1000","auto-rezise":true,"placeholder":"Type here to add","height":8,"match-whole-query":false},on:{"add-item":_vm.updateList},model:{value:(item.text),callback:function ($$v) {_vm.$set(item, "text", $$v)},expression:"item.text"}}),(item.dueDate)?_c('div',{staticClass:"bg-tasqNeutral1700 ml-2.5 flex justify-center items-center font-medium py-1 px-2 mx-1 mb-2.5 w-max gap-x-1 text-center text-primary500 rounded-xl text-xs"},[_c('img',{staticClass:"filter-teal h-3",attrs:{"src":require(`@/assets/sidebar/event-calander.svg`)}}),_vm._v(" "+_vm._s((item.dueDate))+" ")]):_vm._e()],1)]),(!_vm.hideAddNewItem)?_c('span',{staticClass:"material-icons cursor-pointer flex-none",on:{"click":function($event){return _vm.removeItemFromList(item)}}},[_c('mdicon',{staticClass:"cursor-pointer text-tasqNeutral300",class:item.id === _vm.hoverItemId
                ? 'text-tasqAccentError'
                : ' text-tasqNeutral300',attrs:{"name":"trash-can","size":"20"}})],1):_vm._e()]],2)}),0),(_vm.completeItems.length)?_c('div',[_c('p',{staticClass:"text-lg uppercase text-white font-bold px-4 py-4"},[_vm._v(" Completed ")]),_c('draggable',{staticClass:"mx-3",attrs:{"id":`steps-list-input`,"disabled":_vm.hideAddNewItem,"list":_vm.completeItems || []},on:{"end":_vm.updateList}},_vm._l((_vm.completeItems),function(item,key){return _c('div',{key:item.id,staticClass:"flex mb-3 justify-between items-center",on:{"mouseover":function($event){return _vm.updateHoverItem(item)},"mouseleave":function($event){_vm.hoverItemId = ''}}},[[_c('mdicon',{staticClass:"cursor-pointer flex-none text-tasqNeutral300",attrs:{"name":"drag-horizontal-variant","size":"20"}}),_c('div',{staticClass:"flex w-full mx-2 flex-row justify-start items-start px-4 rounded-lg shadow border-accentSuccess200 bg-accentSuccess200 bg-opacity-10 border"},[_c('span',{staticClass:"inline-block mt-3 border-accentSuccess200 text bg-accentSuccess200",class:item.checked
                  ? 'tasq-checkbox--checked--complete'
                  : 'tasq-checkbox checkbox-style',staticStyle:{"background":"theme('colors.accentSuccess200') !important"},attrs:{"type":"checkbox"},on:{"click":function($event){return _vm.updateItemState(item)}}}),_c('div',{staticClass:"w-full"},[_c('GptAutoSuggestion',{ref:item.id,refInFor:true,staticClass:"border-none resize-none w-full outline-none text-accentSuccess200 text-base inline-block rounded-lg bg-transparent",attrs:{"suggestions":_vm.userNames,"trigger-character":"@","background":"bg-tasqNeutral1000","placeholder":"Type here to add","height":8,"width":415,"skidding":-8,"auto-rezise":true,"disabled":_vm.hideAddNewItem,"match-whole-query":false},model:{value:(item.text),callback:function ($$v) {_vm.$set(item, "text", $$v)},expression:"item.text"}}),(item.dueDate)?_c('div',{staticClass:"bg-primary1300 ml-2.5 flex justify-center items-center font-medium py-1 px-2 mx-1 mb-2.5 w-max gap-x-1 text-center text-accentSuccess200 rounded-xl text-xs"},[_c('img',{staticClass:"filter-green h-3",attrs:{"src":require(`@/assets/sidebar/event-calander.svg`)}}),_vm._v(" "+_vm._s(( item.dueDate))+" ")]):_vm._e()],1)]),(!_vm.hideAddNewItem)?_c('span',{staticClass:"material-icons cursor-pointer flex-none",on:{"click":function($event){return _vm.removeItemFromList(item)}}},[_c('mdicon',{staticClass:"cursor-pointer text-tasqNeutral300",class:item.id === _vm.hoverItemId
                  ? 'text-accentError200'
                  : ' text-tasqNeutral300',attrs:{"name":"trash-can","size":"20"}})],1):_vm._e()]],2)}),0)],1):_vm._e()],1):_c('div',{staticClass:"rounded-lg w-full"},[(_vm.currentItem)?_c('div',{staticClass:"flex flex-row justify-start items-center px-4 my-2 rounded-lg hover:border border-primary500 shadow item-bg",class:_vm.currentItem.checked
          ? 'border-accentSuccess200  bg-accentSuccess200 bg-opacity-10 border'
          : 'item-bg'},[_c('span',{staticClass:"inline-block",class:_vm.currentItem.checked
            ? 'tasq-checkbox--checked'
            : 'tasq-checkbox checkbox-style',attrs:{"type":"checkbox"},on:{"click":function($event){return _vm.updateItemState(_vm.currentItem)}}}),_c('GptAutoSuggestion',{staticClass:"border-none resize-none text-tasqNeutral50 inline-block rounded-sm bg-transparent w-full",attrs:{"suggestions":_vm.userNames,"trigger-character":"@","background":"bg-tasqNeutral1000","placeholder":"Type here to add","height":8,"match-whole-query":false},on:{"add-item":_vm.updateList},model:{value:(_vm.currentItem.text),callback:function ($$v) {_vm.$set(_vm.currentItem, "text", $$v)},expression:"currentItem.text"}})],1):_vm._e(),_c('div',{staticClass:"flex flex-row justify-start items-center px-4 rounded-lg",staticStyle:{"background":"#0d2136"}},[_c('span',{staticClass:"inline-block",class:_vm.listItem.checked ? 'tasq-checkbox--checked' : 'tasq-checkbox',attrs:{"type":"checkbox"},on:{"click":function($event){_vm.listItem.checked = !_vm.listItem.checked}}}),_c('GptAutoSuggestion',{staticClass:"border-none resize-none text-tasqNeutral50 inline-block rounded-sm bg-transparent w-full",attrs:{"suggestions":_vm.userNames,"trigger-character":"@","background":"bg-tasqNeutral1000 ","placeholder":"Type here to add","height":8,"match-whole-query":false},on:{"add-item":_vm.addItemToList,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return _vm.addItemToList.apply(null, arguments)}},model:{value:(_vm.listItem.text),callback:function ($$v) {_vm.$set(_vm.listItem, "text", $$v)},expression:"listItem.text"}})],1)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }